  <template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
				<ul class="nav nav-tabs pt-3" style="border: 0;">
  					<li class="active"><a href="#" class="btn btn-sm btn-head text-white">Leads Customer</a></li>
  				</ul>
        <div class="card new-card">
          <div class="card-body">

            <ContactManagementBoard :show-action="true"></ContactManagementBoard>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

  <script>
// import Vuetable from 'vuetable-2/src/components/Vuetable'
// import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
// import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
// import VueFilterBar from '@components/Vuetable/VueFilterBar'

import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
import ContactManagementBoard from '@components/ContactManagement/ContactManagementBoard'

import { mapActions } from 'vuex'
// import { customersData } from './dataCustomers'

/**
 * Customers component
 */
export default {
  page: {
    title: 'Leads Customer',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Vuetable,
    // VueFilterBar,
    // VuetablePagination,
    // VuetablePaginationInfo,
    ContactManagementBoard,
    Layout,
    // PageHeader,
  },
  data() {
    return {
      title: 'Leads Customer',
      moreParams: {},
      deleteData: {},
      httpOptions: {
        headers: {
        //   Authorization:
            // 'Bearer ' + this.$store.state.auth.currentToken.access_token,
        },
      },
      // apiUrl: this.$store.state.rootApi + '/api/quotation',
      items: [
        {
          text: 'Master',
          href: '/',
        },
        {
          text: 'Leads Customer',
          href: '/contact-management',
        },
        {
          text: 'Board',
          active: true,
        },
      ],
      lengthMenu: [5, 10, 25, 50, 100],
      perPage: 5,
      list: [{
          upvotes: [{
              data: "test",
              id: "dsk"
          },
          {
              data: "sdgdsg",
              id: "sad"
          }]
      }],

    }
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh()
    },
  },
  mounted() {
    // this.data = this.customersData
    this.$events.listen('filter-set', (filterText) =>
      this.onFilterSet(filterText)
    )
    this.$events.listen('filter-reset', () => this.onFilterReset())
  },
  beforeDestroy() {
    this.$events.remove('filter-set')
    this.$events.remove('filter-reset')
  },
  methods: {
    ...mapActions('sevp', ['deleteSevp']),
    /**
     * Table Length Change
     *
     * @return  {[type]}  [return description]
     */
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet(filterText) {
      this.moreParams = {
        filter: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTable() {
      this.isLoadingTable = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccess() {
      this.isLoadingTable = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailed() {
      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
    },

    onAction(action, rowData, rowIndex) {
      if (action === 'edit-item') {
        this.$router.push({
          name: 'SEVP Edit',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'detail-item') {
        this.$router.push({
          name: 'SEVP Detail',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'view-item') {
        this.$router.push({
          name: 'SEVP View',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'delete-item') {
        this.showConfirmDelete(rowData.id)
      }
    },

    showConfirmDelete(id) {
      this.deleteData.id = id
      let self = this
      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.$swal('Deleted!', 'Your data has been deleted.', 'error')
          self.onDelete()
        } else if (result.dismiss) {
          this.$swal('Cancelled', 'Your data is safe :)', 'error')
        }
      })
    },
    /**
     * Delete Brand
     *
     * @return  {[type]}  [return description]
     */
    onDelete() {
      // this.isLoadingTable = true
      this.deleteSevp(this.deleteData.id)
        .then((res) => {
          this.successAlert()
          // this.isLoadingTable = false;
          this.$refs.vuetable.refresh()
        })
        .catch((err) => {
          this.errorAlert()
          console.log(err.response.message)
          // this.isLoadingTable = false;
        })
    },
  },
}
</script>
